$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                scrollTo(target);
                return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements() {
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            } else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function () {
        $(this).toggleClass("active");
        $(".js-nav-mobile").toggleClass("active")
        $("body").toggleClass("freeze");
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * js-nav click anchor linkls
    // *
    // *
    $(".js-nav-mobile ul li a").on("click", function () {
        $(".js-nav-mobile").removeClass("active");
        $(".js-navbutton").removeClass("active");
        $("body").removeClass("freeze");

    })





    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * swiper
    // *
    // *
    const swiperHalf = new Swiper('.swiper-half', {
        // Optional parameters
        loop: false,
        autoheight: true,
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: {
            delay: 8000,
        },


        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar-half',
            draggable: true,
        },
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1.2,
                spaceBetween: 10
            },
            900: {
                slidesPerView: 1.1,
                spaceBetween: 10
            },
            // when window width is >= 480px
            1200: {
                slidesPerView: 1,
                spaceBetween: 20
            },

        }
    });

    const swiperFull = new Swiper('.swiper-full', {
        // Optional parameters
        loop: false,
        autoheight: true,
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: {
            delay: 8000,
        },


        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar-full',
            draggable: true,
        },
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= X
            320: {
                spaceBetween: 10
            },
            900: {
                spaceBetween: 20
            },

        }
    });

    const swiperGallery = new Swiper('.swiper-gallery', {
        // Optional parameters
        loop: false,
        autoheight: true,
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: {
            delay: 8000,
        },

        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar-gallery',
            hide: false,
            draggable: true,
        },
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= X
            320: {
                slidesPerView: 1.2,
                spaceBetween: 10
            },
            600: {
                slidesPerView: 2.4,
                spaceBetween: 20
            },
            800: {
                slidesPerView: 3.4,
                spaceBetween: 20
            },
            1000: {
                slidesPerView: 4.4,
                spaceBetween: 30
            },
            1200: {
                slidesPerView: 1,
                spaceBetween: 40
            },

        }
    });

    const swiperIntro = new Swiper('.swiper-intro', {
        // Optional parameters
        loop: false,
        autoheight: true,
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: {
            delay: 8000,
        },


        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar-intro',
            draggable: true,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'custom',
            renderCustom: function (swiperIntro, current, total) {
                return current + ' - ' + (total);
            }
        },

    });

    // const swiperMobile = new Swiper('.swiper-mobile', {
    //     // Optional parameters
    //     loop: false,
    //     slidesPerView: 1,
    //     spaceBetween: 20,

    // });

    var init = false;
    var swiper;
    function swiperMobile() {
        if (window.innerWidth <= 768) {
            if (!init) {
                init = true;
                swiper = new Swiper(".swiper-mobile", {
                    direction: "horizontal",
                    slidesPerView: 1.1,
                    spaceBetween: 10,
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets',
                    },
                });
            }
        } else if (init) {
            if ($(".swiper-mobile").length > 0) {
                swiper.destroy();
                init = false;
            }
        }
    }
    swiperMobile();
    window.addEventListener("resize", swiperMobile);

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * text-getter-setter
    // *
    // *
    $(".js-text-setter").each(function () {
        var text = $(this).html();
        $(this).parents(".js-text-wrapper").find(".js-text-getter").html(text);
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *

    $(".js-accordion-button").click(function () {
        $(this).toggleClass("active").siblings(".js-accordion-content").slideToggle();
        $(this).parent(".js-accordion").siblings(".js-accordion").find(".js-accordion-button").removeClass("active").siblings(".js-accordion-content").slideUp();
    });

    $(".js-accordion-wrapper").find(".js-accordion").eq(0).find(".js-accordion-button").trigger("click");


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * hero Animation
    // *
    // *
    var $hero = $(".js-hero"),
        $heroImage = $(".js-hero-image"),
        $heroLogo = $(".js-hero-logo"),
        $heroDrop = $(".js-hero-drop");

    gsap.to($heroImage, {
        scale: 1.2,
        scrollTrigger: {
            trigger: $hero,
            scrub: true,
            markers: false,
            start: "top-=30% top"
        }
    });

    gsap.to($heroLogo, {
        y: '30vh',
        scrollTrigger: {
            trigger: $hero,
            scrub: true,
            markers: false,
            start: "top-=30% top"
        }
    });

    gsap.to($heroDrop, {
        opacity: 0,
        scale: 0,
        scrollTrigger: {
            trigger: $hero,
            scrub: true,
            markers: false,
            start: "top-=40 top"
        }
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * header Animation
    // *
    // *
    ScrollTrigger.create({
        trigger: '.js-invert-header',
        start: 'top top+=40',
        end: 'bottom top+=40',
        toggleClass: {
            targets: '.js-header-inner',
            className: 'inverted'
        },
        markers: false
    });





    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * hCategory line animation
    // *
    // *
    var $hCategoryLine = $(".hCategory span");

    $hCategoryLine.each(function () {
        gsap.to($(this), {
            width: '100%',
            scrollTrigger: {
                trigger: $(this),
                scrub: true,
                markers: false,
                end: "top 30%"
            }
        });
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * milestone
    // *
    // *
    var $milestone = $(".history__milestone");

    $milestone.each(function () {
        gsap.fromTo($(this), {
            x: '20vw',

        }, {
            x: '-20vw',
            autoAlpha: 1,
            scrollTrigger: {
                trigger: $(this),
                scrub: true,
                markers: false,
                start: "center bottom",
                end: "center top"
            }
        });
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * history
    // *
    // *
    var $history = $(".history");

    $history.each(function () {
        gsap.to($(this), {
            scrollTrigger: {
                trigger: $(this),
                toggleClass: "active",
                markers: false,
                start: "top center",
                end: "bottom center"
            }
        });
    });





    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * imageOrientation
    // *
    // *
    $(".js-historyimage").each(function () {
        // Calculate aspect ratio
        var aspectRatio = $(this).width() / $(this).height();

        // Conditional statement
        if (aspectRatio > 1) {
            // Image is landscape
            $(this).addClass("landscape");
        } else if (aspectRatio < 1) {
            // Image is portrait
            $(this).addClass("portrait");
        } else {
            // Image is square
            $(this).addClass("square");
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    // * dialog window
    // *
    // *
    // NEWSLETTER
    var newsletterDialog = $(".js-newsletter");
    var newsletterState = sessionStorage.getItem('newsletterState');

    $(".js-close-newsletter").click(function () {
        $(this).parents(".js-newsletter").get(0).close();
        sessionStorage.setItem('newsletterState', true);
        newsletterDialog.removeClass("active");
        $("body").removeClass("freeze");
    });

    if (!newsletterState && newsletterDialog.length > 0) {
        setTimeout(() => {
            newsletterDialog.get(0).showModal();
            newsletterDialog.addClass("active");
            $("body").addClass("freeze");
        }, 20000);
    }

    // EVENT

    var eventState = sessionStorage.getItem('eventState');
    var eventDialog = $(".js-event");

    $(".js-close-event").click(function () {
        $(this).parents(".js-event").get(0).close();
        sessionStorage.setItem('eventState', true);
        eventDialog.removeClass("active");
        $("body").removeClass("freeze");
    });

    if (!eventState && eventDialog.length > 0) {
        setTimeout(() => {
            eventDialog.get(0).showModal();
            eventDialog.addClass("active");
            $("body").addClass("freeze");
        }, 20000);
    }

    $("html").bind("mouseleave", function () {
        if (!eventState && eventDialog.length > 0) {
            eventDialog.get(0).showModal();
            eventDialog.addClass("active");
            $("body").addClass("freeze");
        }
        if (!newsletterState && newsletterDialog.length > 0) {
            newsletterDialog.get(0).showModal();
            newsletterDialog.addClass("active");
            $("body").addClass("freeze");
        }
        $("html").unbind("mouseleave");
    });


});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function () {
    if ($(window).scrollTop() >= 40 ) {
        $(".js-header-anchor").addClass("sticky");
    } else {
        $(".js-header-anchor").removeClass("sticky");
    }
});

